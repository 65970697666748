.expozy-logo {
    max-width: 100px;
}

.hero-banner-five .hero-heading span, .fancy-text-block-twentyOne .text-wrapper p span, .title-style-six h2 span, .title-style-six h2 span {
    color: #E52711 !important;
}

::-webkit-scrollbar-thumb {
    background: #E52711 !important;
}

.f-none-m-auto {
    float: none;
    margin: 0 auto;
}

.fancy-text-block-twentyOne .text-wrapper p {
    font-size: 40px;
}

.accordion-style-two .card {
    background: transparent !important;
}

body .theme-btn-eleven {
    font-family: "gordita";
    font-weight: 500;
    font-size: 18px;
    line-height: 48px;
    border: 2px solid #000;
    border-radius: 30px;
    color: #000;
    padding: 0 40px;
    transition: all .3s ease-in-out;
}

.footer-logo {
    max-width: 150px;
    width: 100%;
}

ul.list-item-one {
    list-style-type: circle;
}

body .theme-btn-twelve {
    font-family: "gordita";
    font-size: 17px;
    line-height: 48px;
    background: #E52711 !important;
    border: 2px solid #E52711 !important;
    border-radius: 30px;
    color: #fff;
    padding: 0 32px;
    transition: all 0.3s ease-in-out;
}

body .theme-btn-twelve:hover {
    background: transparent !important;
    color: #E52711 !important;
}

.react-tabs__tab--selected button {
    background: #000;
    color: #fff;
}

.po-control-one button {
    width:100%;
}

.theme-menu-seven .navbar-nav .nav-item .nav-link .login-icon-nav {
    fill: #fff;
    max-width:40px;
    width: 100%;
    height:auto;
}

.inner-page-white-nav-items .theme-menu-seven .navbar-nav .nav-item .nav-link {
    color:#000;
}

.inner-page-white-nav-items .theme-menu-seven .navbar-nav .nav-item .nav-link .login-icon-nav {
    max-width:40px;
    width: 100%;
    height:auto;
    fill: #fff;
}

.inner-page-white-nav-items .theme-menu-seven .navbar-nav .nav-item .nav-link .login-icon-nav {
    max-width:40px;
    width: 100%;
    height:auto;
    fill: #000;
}

.inner-page-white-nav-items .theme-menu-seven.fixed .navbar-nav .nav-item .nav-link {
    color:#fff;
}

.inner-page-white-nav-items .theme-menu-seven.fixed .navbar-nav .nav-item .nav-link .login-icon-nav {
    max-width: 40px;
    width:100%;
    fill:#fff;
}

.x-icon {
    filter: opacity(0.5);
}

.svg {
    margin-top:10px;
    margin-bottom: 10px;
}

.text-yellow-400 {
    fill: #E52711;
}

.justify-content-space-between {
    justify-content: space-between;
}

.mb-10 {
    margin-bottom: 5rem;
}

.pentest-icon {
    max-width:100px;
    width:100%;
}

.justify-content-space-between {
    justify-content: space-between !important;
}

.stripe-svg-container {
    align-self: flex-end;
}

.svg-img {
    padding-bottom:20px; 
}

.algolia-svg {
    float:right;
}

.native-apps {
    align-self:center;
}

.strapi-svg-container {
    align-self: center;
}


.svg-title {
    text-align: center;
    font-weight:bold;
}

.omnichannel-section {
    background: #EDF5F8;
    padding-top:2rem;
    padding-bottom:2rem;
    margin-top:3rem;
    margin-bottom: 3rem;
}

body .theme-btn-thirteen:hover {
    background: #E52711 !important;
}

.terms_and_policy .nav-item .nav-link.active {
    color: #000 !important;
}


.trial-button:hover {
    cursor: pointer;
}

.bg-white {
    background: #fff;
    padding:1rem !important;
    border-radius:10px;
    z-index: 999999999999;
}

.modal-register {
    z-index: 9999999999;
}


.theme-main-menu.sticky-menu.fixed {
    z-index: 999 !important;
}


.close-modal-button:hover {
    cursor: pointer;
}

.padding-bottom-50px {
    padding-top: 50px !important; 
}

.user-data-form .input-group-meta textarea {
    font-family: "Rubik", sans-serif;
    width: 100%;
    height: 100%;
    font-size: 18px;
    border: none;
    border-bottom: solid 2px #000;
    padding: 0 52px 0 0;
    color: var(--heading);
    background: transparent;
}


.user-data-form .input-group-meta .css-b62m3t-container {
    font-family: "Rubik", sans-serif;
    width: 100%;
    height: 100%;
    font-size: 18px;
    border: none;
    border-bottom: solid 2px #000;
    padding: 0 52px 0 0;
    color: var(--heading);
    background: transparent;
} 

.css-1s2u09g-control {
    border-color: transparent !important;
}

.css-319lph-ValueContainer {
    padding: 2px 0 !important;
}

.partner-form {
    height:500px;
    overflow-y: scroll;
}

.partner-heading-underline {
    position: relative !important;
    max-width: 200px;
    float: none;
    margin: 0 auto;
    bottom: -10px !important;
}

.partner-card-content p {
    font-size: 18px !important;
}

.partner-card-content {
    padding: 45px 80px 45px !important;
}

.partner-card-content .social-icon a {
    width: 42px;
    height: 42px;
    line-height: 42px;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    color: #000;
    position: relative;
    z-index: 1;
    margin-right: 10px;
    transition: all .3s ease-in-out;
}

.partner-card-content .social-icon li:first-child a:before {
    background: #6f55ff;
}

.vertical-timeline-element-content {
    background: #fff !important;
    color: #000 !important;
    border: 2px solid #eef3f7;
    border-radius: 10px;
    box-shadow: none !important;
}

.vertical-timeline-element-title {
    font-family: "gilroy-black";
}

.vertical-timeline-element-subtitle, .vertical-timeline-element-date {
    font-family: "gilroy-bold" !important;
}


.vertical-timeline-element-content p {
    font-family: "gilroy-bold" !important;
}

.scroll-top {
    bottom: 100px;
}

.scroll-top {
    bottom: 100px !important; 
}

.scroll::-webkit-scrollbar {
    width: 5px;
}

.pricing-page-table::-webkit-scrollbar-track {
    height:3px !important;
}

.pricing-page-table::-webkit-scrollbar-thumb {
    height:3px !important;
}
​

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
    .login-icon-nav {
        max-width: 40px;
        height: auto;
       }
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    .login-icon-nav {
        max-width: 40px;
        height: auto;
       }
       .pricing-page-table {
        width: 100%;
        overflow-x: scroll;
       }
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
   .login-icon-nav {
    max-width: 40px;
    height: auto;
   }
   .pricing-page-table {
    width: 100%;
    overflow-x: scroll;
   }
    
  }